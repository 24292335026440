import React from 'react';

import { slice, openArray } from "zarr";
import { MapContainer, TileLayer, Polyline } from 'react-leaflet'

const zip = (arr, ...arrs) => {
  return arr.map((val, i) => arrs.reduce((a, arr) => [...a, arr[i]], [val]));
}

const zarray_to_vinfo = (zarray) => {
    return { shape: zarray.shape }
}

const zattrs_to_vinfo = (zattrs) => {
    const {_ARRAY_DIMENSIONS, ...attrs} = zattrs;
    return { dims: _ARRAY_DIMENSIONS, attrs: attrs }
}

const varinfo = (metadata) => {
    var vinfo = {}
    for (let [key, value] of Object.entries(metadata)) {
        var parts = key.split("/")
        const suffix = parts.pop()
        const varname = parts.join("/")
        if (varname.length === 0) {
            continue
        }
        if (suffix === ".zarray") {
            vinfo = {...vinfo, [varname]: { ...(vinfo[varname] || {}), ...zarray_to_vinfo(value) }}
        }
        if (suffix === ".zattrs") {
            vinfo = {...vinfo, [varname]: { ...(vinfo[varname] || {}), ...zattrs_to_vinfo(value) }}
        }
    }
    return vinfo;
}

const collect_dims = (varinfo) => {
    return Object.values(varinfo).map(vi => Object.fromEntries(zip(vi.dims, vi.shape))).reduce((acc, cur) => { return {...acc, ...cur} })
}

const find_coords = (varinfo) => {
    return Object.keys(varinfo).map(vn => {
            const vi = varinfo[vn];
            var co = {};
            if (vi.attrs.axis !== undefined) {
                co[vi.attrs.axis] = vn
            }
            if (vi.attrs.standard_name === "latitude") {
                co.lat = vn
            }
            if (vi.attrs.standard_name === "longitude") {
                co.lon = vn
            }
            return co;
        }).reduce((acc, cur) => { return {...acc, ...cur} })
}

export class DSView extends React.Component {
  constructor() {
    super();
    this.state = { state: "loading" };
  }

  loadData() {
    fetch(this.props.src + "/.zmetadata")
          .then(r => r.json())
          .then(d => {
              this.setState({ metadata: d.metadata, state: "loaded" })
            })
          .catch(error => {
              console.error(error)
              this.setState({ metadata: undefined, state: "error", error: error })
            });
  }

  componentDidMount() {
      this.loadData();
  }

  componentDidUpdate(prevProps, prevState) {
      if (prevProps.src !== this.props.src) {
          this.setState({ metadata: undefined, state: "loading" })
          this.loadData();
      }
      if (prevState.state !== "loaded" && this.state.state == "loaded") {
          this.fetch_viz();
      }
  }

  async fetch_array(name) {
      const url = new URL(this.props.src)
      const z = await openArray({
        store: url.protocol + "//" + url.host + "/",
        path: url.pathname + "/" + name,
        mode: "r"
      });
      console.log(z);
      return await z.get([null]);
  }

  fetch_viz() {
      const globals = this.state.metadata[".zattrs"];
      const vinfo = varinfo(this.state.metadata);
      const dinfo = collect_dims(vinfo);
      const coords = find_coords(vinfo);
      if (globals.featureType === "trajectory"
          && coords.lat && coords.lon
          && vinfo[coords.lat].shape.length === 1 && vinfo[coords.lon].shape.length === 1) {
            this.setState({...this.state, vizdata: "loading"});
            Promise.all([
                this.fetch_array(coords.lat).then(x => ({x: x.data})),
                this.fetch_array(coords.lon).then(y => ({y: y.data})),
            ]).then(parts => {
                const vizdata = {
                    ...parts.reduce((acc, cur) => ({...acc, ...cur})),
                    type: "trajectory",
                };
                this.setState({...this.state, vizdata});
            });
      }
  }

  render_viz() {
    if (!this.state.vizdata) {
        return
    }
    if (this.state.vizdata === "loading") {
        return <h2>loading visualization...</h2>
    }
    if (this.state.vizdata.type === "trajectory") {
        const {x, y} = this.state.vizdata;
        const cx = x.reduce((a, b) => a + b) / x.length;
        const cy = y.reduce((a, b) => a + b) / y.length;
        const lo_x = x.reduce((a, b) => a < b ? a : b);
        const lo_y = y.reduce((a, b) => a < b ? a : b);
        const hi_x = x.reduce((a, b) => a > b ? a : b);
        const hi_y = y.reduce((a, b) => a > b ? a : b);

        const center = [cx, cy];
        const polyline = zip([...x], [...y]); // converts ZarrArrays into plain JS arrays

        const linestyle = {color: "red"};

        return <>
                <h2>trajectory</h2>
                <div height="300px">
                <MapContainer key="trajectory_map" center={ center } zoom={7} scrollWheelZoom={false} height="300px">
                    <TileLayer
                      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                   <Polyline pathOptions={linestyle} positions={polyline} />
                </MapContainer>
                </div>
            </>
    }
  }

  render_loaded() {
      const globals = this.state.metadata[".zattrs"];
      const vinfo = varinfo(this.state.metadata);
      const dinfo = collect_dims(vinfo);
      const coords = find_coords(vinfo);
      console.log(globals)
      console.log(vinfo)
      console.log(coords)
      const dimnames = Object.keys(dinfo).sort();
      const varnames = Object.keys(vinfo).sort();

      return (
            <>
              <h1>{ this.state.metadata[".zattrs"].title }</h1>
              <code>{ this.props.src }</code>
              <h2>Dimensions</h2>
              <ul>
              {
                  dimnames.map(dimname => {
                      const size = dinfo[dimname];
                      return (<li key={ "dim-" + dimname }>{ dimname } ( { size } )</li>)
                  })
              }
              </ul>
              <h2>Variables</h2>
              <ul>
              {
                  varnames.map(varname => {
                      const info = vinfo[varname];
                      const shape = "(" + info.dims.join(", ") + ")";
                      return (<li key={ "var-" + varname }>{ varname } { shape }</li>)
                  })
              }
              </ul>
              { this.render_viz() }
            </>
          )

  }

  render() {
    switch (this.state.state) {
        case "loading":
            return <h1>DSView loading...</h1>
        case "error":
            return <><h1>Error</h1>{ this.state.error.message }</>
        case "loaded":
            return this.render_loaded()
        default:
            return <><h1>Unknown State</h1></>
    }
  }
}
