import React from 'react';
import { HashRouter, useLocation, Link } from 'react-router-dom';
import { DSView } from './features/dsview/DSView';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { PathBreadcrumb } from './features/pathbreadcrumb/PathBreadcrumb';
import './App.css';

//import { useIpfsGatewayFactory } from './hooks/use-ipfs-factory.js'
//import useIpfs from './hooks/use-ipfs.js'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'leaflet/dist/leaflet.css'

// const ipfsname = "QmXfoSuYNUVdngDUv2uDG8cXWjruFA7964462pWnFjmNDA/level3";
const gateway = "https://ipfs.io/ipfs";

const example_datasets = [
    //"/QmXphJafuuHv27EERUWSuQRhAA4LGJBvSjNkvSpwy8fFWi",
    "/QmXfoSuYNUVdngDUv2uDG8cXWjruFA7964462pWnFjmNDA/level2/HALO-0205_s07",
    "/QmXfoSuYNUVdngDUv2uDG8cXWjruFA7964462pWnFjmNDA/level3",
    "/QmXfoSuYNUVdngDUv2uDG8cXWjruFA7964462pWnFjmNDA/level4",
    "/QmdzShXdxQAjzTGWarDCae7EyVxwtgaFXBcFzpakYywqRu",
];


function MainView() {
    const ipfsname = useLocation().pathname;
    
    //const { ipfs } = useIpfsGatewayFactory();
    //console.log(ipfs);
    // const ls = useIpfs(ipfs, 'ls', {ipfsPath: ipfsname});

    console.log(ipfsname);
    //console.log(ls);
    if (ipfsname.length > 1) {
        return (
          <>
          <PathBreadcrumb path={ ipfsname } />
          <Row>
            <Col>
              <DSView src={ gateway + ipfsname } />
            </Col>
          </Row>
          </>
        )
    } else {
        return (
            <div className="App">
                <h1>Distributed Datasets</h1>
                <p>Try one of the following</p>
                <ul>
                    {
                        example_datasets.map(url => <li key={ url }><Link to={ url }>{ url }</Link></li>)
                    }
                </ul>
            </div>
        )
    }
}

function App() {
  return (
      <HashRouter>
        <Container>
            <MainView/>
        </Container>
      </HashRouter>
  );
}

export default App;
