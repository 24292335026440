import React from 'react';

import Breadcrumb from 'react-bootstrap/Breadcrumb'

export class PathBreadcrumb extends React.Component {
  render() {
      const segments = this.props.path.substr(1).split("/");
      return (
          <Breadcrumb>
          <Breadcrumb.Item href="#/" key="bc-home">Home</Breadcrumb.Item>
          {
            segments.map((seg, i) => {
                var attrs = {
                    href: "#" + segments.slice(0, i+1).join("/"),
                    key: "bc-" + i
                };
                if (i === segments.length-1) {
                    attrs["active"] = true;
                }
                return <Breadcrumb.Item {...attrs}>{ seg }</Breadcrumb.Item>
            })
          }
          </Breadcrumb>
      )
  }
}
